<template>
    <div class="team-index-page">
        
        <div class="page-tools-content" style="margin: 0; border: none;">
            <div class="left">
                <el-button size="small" plain round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage">刷新</el-button>
            </div>
            <div class="right">
                <div style="width:220px;">
                    <el-input placeholder="输入姓名/手机号/邮箱" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchKw" @input="inputSearchEvent"></el-input>
                </div>
                <div class="language-group margin-l-10">
                    <div style="width:105px;">
                        <el-select size="small" placeholder="源语言" clearable v-model="source" @change="selectSearchEvent">
                            <template v-for="item in langFilters">
                                <el-option :key="item.value" :value="item.value" :label="item.text" :disabled="target === item.value"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div style="margin-left:5px;">
                        <svg-icon name="ib-arrow-right" style="font-size:14px;color:#00000033;"></svg-icon>
                    </div>
                    <div style="width:105px;margin-left:5px;">
                        <el-select size="small" placeholder="目标语言" clearable v-model="target" @change="selectSearchEvent">
                            <template v-for="item in langFilters">
                                <el-option :key="item.value" :value="item.value" :label="item.text" :disabled="source === item.value"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
                <div style="width:114px;margin-left:10px;">
                    <el-select v-model="teamIds" size="small" clearable placeholder="按团队筛选" @change="selectSearchEvent">
                        <template v-for="item in teamFilters">
                            <el-option :label="item.text" :value="item.value" :key="item.value"></el-option>
                        </template>
                    </el-select>
                </div>
                <div style="width:124px;margin-left:10px;">
                    <el-select v-model="roles" size="small" clearable placeholder="按角色筛选" @change="selectSearchEvent">
                        <template v-for="item in roleFilters">
                            <el-option :label="item.text" :value="item.value" :key="item.value"></el-option>
                        </template>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- @filter-change="filterChangeEvent" -->
        <el-table :data="list" v-loading="refreshLoading" style="width: 100%;"> 
          <el-table-column type="index" label="序号" width="65" header-align="center" align="center"></el-table-column>
          <el-table-column label="姓名" prop="alias" header-align="center" align="center"></el-table-column>
          <el-table-column label="角色" prop="roleName" header-align="center" align="center" column-key="roles"></el-table-column>
          <el-table-column label="语言" prop="langDirection" header-align="center" align="center" :show-overflow-tooltip="true" column-key="langs"></el-table-column>
          <!-- <el-table-column label="擅长领域"></el-table-column> -->
          <el-table-column label="团队" prop="teamList" header-align="center" align="center" :show-overflow-tooltip="true" column-key="teamIds">
            <template slot-scope="scope">
                <template v-for="(item, index) in scope.row.teamList">{{item.teamName}}<template v-if="index < scope.row.teamList.length-1">、</template></template>
            </template>
          </el-table-column>
          <el-table-column label="系统编码" prop="systemCode" header-align="center" align="center"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="150">
            <template slot-scope="scope">
                <el-button type="text" class="padding-0 color-link color-link:hover" @click="showContactInfo(scope.row)">查看联系方式</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>

        <el-dialog title="联系方式" :visible.sync="dialogShow" destroy-on-close width="430px" top="20px">
            <template v-if="info">
                <div class="flex-column flex-align-center">
                    <el-avatar :size="60" :src="info.avatar" icon="el-icon-user" class="fonts-36"></el-avatar>
                    <div class="margin-t-10 text-weight-600">{{info.alias}}</div>
                </div>
                <div class="flex margin-y-20">
                    <div class="flex-1 flex-column flex-align-center">
                        <div class="contact-type-block"><svg-icon name="ib-phone"></svg-icon></div>
                        <div class="margin-t-16">{{info.phone}}</div>
                        <a href="javascript:;" class="margin-t-10" @click="copyText(info.phone)">复制</a>
                    </div>
                    <div class="flex-1 flex-column flex-align-center">
                        <div class="contact-type-block"><svg-icon name="ib-letter"></svg-icon></div>
                        <div class="margin-t-16">{{info.email || '—'}}</div>
                        <a href="javascript:;" class="margin-t-10" @click="copyText(info.email)" v-if="info.email">复制</a>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import tools from '@/utils/tools';
import { teamApi, commonApi } from '@/utils/api';
export default {
    data() {
        return {
            list: [],
            
            roles: '',
            roleFilters: [],
            source: '',
            target: '',
            langs: '',
            langFilters: [],
            teamIds: '',
            teamFilters: [],

            searchTimer: null,
            searchKw: '',
            refreshLoading: false,
            pageNumber: 1,
            pageSize: 16,
            total: 0,

            dialogShow: false,
            info: null,

        }
    },
    methods: {
        initPage() {
            let url = teamApi.queryInternalMemberList;
            let postData = {
                pageNo: this.pageNumber,
                pageSize: this.pageSize,
                keyword: this.searchKw,
                roles: this.roles ? [this.roles] : undefined,
                // langs: this.langs.length ? this.langs : undefined,
                source: this.source ? this.source : undefined,
                target: this.target ? this.target : undefined,
                teamIds: this.teamIds ? [this.teamIds] : undefined,
            };
            this.refreshLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200) {
                    this.list = res.data.data;
                    this.total = res.data.total;
                }
            }).finally(() => {
                this.refreshLoading = false;
            });
        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.roleFilters = res.data.roleFilters;
                    this.langFilters = res.data.langFilters;
                    this.teamFilters = res.data.teamFilters;
                }
            });
        },
        inputSearchEvent() {
            let self = this;
            if(this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                self.initPage();
            }, 500)
        },
        selectSearchEvent() {
            this.initPage();
        },
        filterChangeEvent(filters) {
            let key = Object.keys(filters)[0];
            this[key] = filters[key];
            this.initPage();
        },
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.initPage();
        },
        showContactInfo(row) {
            let url = teamApi.queryMemberDetail;
            let postData = {
                organizationId: row.organizationId,
                memberId: row.memberId,
            };
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.dialogShow = true;
                    this.info = res.data;
                }
            });
        },
        copyText(text) {
            let self = this;
            this.$copyText(text).then(function (e) {
                self.$message.success('已复制到剪切板');
            }, function (e) {
                self.$message.error('复制到剪切板失败，请重试');
            })
        },
    },
    mounted() {
        this.initPage();
        this.queryTeamOptions();
    },
    beforeDestroy() {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
    }
    
}
</script>
<style lang="scss" scoped>
.team-index-page{
    ::v-deep .el-table {
        background-color: transparent;
        &::before {
            height: 0;
        }
        th {
            padding: 8px 0;
            font-size: 15px;
            color: #999999;
        }
        .el-table__row {
            td {
                font-size: 14px;
                padding: 13.55px 0;
            }
        }
        .filtered {
            color: green;
            .el-table__column-filter-trigger i {
                color: green;
            }
        }
    }
    .contact-type-block{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #F2F2F2;
        font-size: 20px;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
